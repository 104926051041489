<template>
<!--  /*根据外面的容器进行修改flex属性*/-->
    <div class="help-document">
      <header class="hc-header">
        <h1 class="hc-header-title">{{ documentInfo.sectionHead }}</h1>
      </header>
      <main class="hc-body">
        <div class="hc-body-section" v-for="(item,index) in documentInfo.document">
          <div class="hc-bs-top">
            <h3 class="hc-bs-top-h1">{{ item.head }}</h3>
          </div>
          <div class="hc-bs-content" v-for="(item2,index2) in item.data">
            <div class="hc-bs-content-body">
              <div class="hc-bs-content-body-sub">
                <div class="hc-bs-cb-statement">
                  <span v-if="item2.text">{{item2.text}}</span>
                  <el-image fit="contain"
                            v-if="item2.imgUrl"
                            :preview-src-list="imagesUrl"
                            :lazy="true"
                            class="hc-bs-cb-statement-img"
                            :src="urlPrefix + item2.imgUrl">
                  </el-image>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer class="hc-body">

      </footer>
    </div>
</template>

<script>
import {colorLog} from "../../util";

export default {
  name: "HelpDocument",
  props:{
    documentInfo:{
      require:true
    },
    urlPrefix:{
      require:true,
    }
  },
  data(){
    let _documentInfo = this.documentInfo;
    return {
      imagesUrl:(()=>{
        let list = [];

        _documentInfo.document.forEach((item,index1)=>{
          item.data.forEach((ctx,index2)=>{
              if(ctx.imgUrl){
                list.push(this.urlPrefix + ctx.imgUrl);
              }
            })
        })

        return list;
      })()
    }
  },
  computed:{
  }
}
</script>

<style scoped>
.help-document {
  font-size: 16px;
  padding: 20px 0 200px;
}

.first-usage-guide * {
  margin: 0;
  white-space: pre-wrap;
}

.hc-header {
  text-align: center;
}

.hc-body {
  text-align: center;
  width: 800px;
  margin: 0 auto;
}
.hc-bs-cb-statement-img{
  width: 80%;
  box-shadow: 0 0 4px 0px var(--gray-2);
  background: var(--page-gray);
}
.hc-bs-cb-statement-img >>> .el-image__inner{
  position: absolute;
  top: 0;
  left:0;
  right:0;
  bottom:0;
  max-height: 600px;
  box-sizing: border-box;
  box-shadow: 0 0 3px 1px var(--gray-2);
}
.hc-header-tip{
  margin:5px 0;
}
.hc-bs-top {
  z-index: 1;
}
.hc-bs-top-h1{
  margin:0;
  height: auto;

  padding: 15px 10px;
  box-sizing: content-box;
  text-align: left;
}
.hc-bs-cb-h3{
  margin:5px 0;
  text-align: left;
}
.hc-bs-content{
  display: inline-block;
  margin:15px 0;
  width: 100%;
}
.hc-bs-content-body{
}
.hc-bs-cb-statement{
  text-align: center;
  padding: 5px;

}
.el-image.hc-bs-cb-statement-img::before {
  content: '';
  width: 100%;
  display: inline-block;
  padding-bottom: 50%;
}
</style>
