<template>
  <div class="document-category">
    <el-tree
             ref="tree"
             :data="category"
             :default-expanded-keys="expandKeys"
             :props="categoryProps"
             :node-key="categoryProps.id"
             @node-click="handleNodeClicked"
             default-expand-all
    ></el-tree>
  </div>
</template>

<script>
import {colorLog} from "../../util";

export default {
  name: "DocumentCategory",
  props:{
    category:{
      require:true,
    },
    categoryProps:{
      require: true
    },
    expandKeys:{
      default:[]
    }
  },
  data(){
    return {
      previousNode:null,
    }
  },
  methods:{
    getPathByNode(node){

    },
    handleNodeClicked(props,node,event){
      if(!props.document){
        this.$nextTick(()=>{
          this.previousNode.classList.add('is-showing');
        })
        return;
      }

      this.$nextTick(()=>{
        if(this.previousNode){
          this.previousNode.classList.remove('is-showing');
          let node = this.previousNode;
          do{
            node = node.parentNode.closest('.el-tree-node');
            if(!node) break;
            node.classList.remove('is-showing-parent');
          } while(node)
        }

        let node = event.vnode.el
        node.classList.add('is-showing');

        do{
          node = node.parentNode.closest('.el-tree-node');
          if(!node) break;
          node.classList.add('is-showing-parent');
        } while(node)

        this.previousNode = event.vnode.el;
        //带着新数据重新打开文档内容组件
        this.currentDocument = props;
      })

      this.$emit('jumpToDocument',props,node,event);

    },
    expendSection(e){

    },

  },
  created() {

  },
  beforeUnmount() {

  }
}
</script>

<style scoped>
p,a,li{
  margin:0;
  text-align: left;
}
.document-category >>> .el-tree{
  background:inherit;
}
.document-category{
  padding: 5px 10px;
}

.document-category >>> .is-current>.el-tree-node__content:first-child{
  background: unset !important;
  color:unset !important;
}
.document-category >>> .is-showing{
  /*background: var(--lightBlue) !important;*/
  /*color:#fff;*/
}
.document-category >>> .is-showing-parent>.el-tree-node__content:first-child{
  /*background: var(--hoverLightBlue) !important;*/
}
.document-category >>> .el-tree-node__content:hover {
  background-color: unset !important;
}
</style>
