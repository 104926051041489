<template>
  <div class="help-center">
    <div class="help-center-left">
      <MetaGoLogo class="hc-left-logo"></MetaGoLogo>
      <DocumentCategory
                        ref="documentCategory"
                        class="help-center-category"
                        :categoryProps="categoryProps"
                        :expand-keys="expandKeys"
                        :category="category"
                        @jumpToDocument="handleJumpToDocument"
      ></DocumentCategory>
    </div>
    <div class="first-shrink-area"></div>
    <el-scrollbar max-height="100vh" class="help-center-content-wrap">
      <div class="help-center-content" >
        <div class="second-shrink-area throttle"></div>
        <HelpDocument
            class="help-center-content-document"
            v-if="currentDocument"
            :documentInfo="currentDocument"
            :url-prefix="urlPrefix"
        ></HelpDocument>
        <div class="second-shrink-area"></div>
      </div>
    </el-scrollbar>

  </div>
</template>

<script>
import {defineAsyncComponent} from "vue";
import DocumentCategory from "./DocumentCategory";
import {colorLog} from "../../util";
import HelpDocument from "./HelpDocument";
import MetaGoLogo from "../../components/universalUI/MetaGoLogo";

export default {
  name: "HelpCenter",
  data() {
    let category = [
      {
        "sectionHead": "首次使用指南",
        "data": [
          {
            "sectionHead": "快速了解",
            "document": [
              {
                "head": "在MetaGO，您可以轻松创建、上传合同，体验方便快捷的合同全生命周期管理。",
                "data": [
                  {
                  }
                ]
              }
            ],
            "id": "a0a0"
          }
        ],
        "id": "a0"
      },
      {
        "sectionHead": "使用教程",
        "data": [
          {
            "sectionHead": "一、上传与自动抽取概览",
            "document": [
              {
                "head": "1. 点击“新建文档”",
                "data": [
                  {
                    "imgUrl": "1.newContract.png"
                  }
                ]
              },
              {
                "head": "2.选择“起草合同”",
                "data": [
                  {
                    "imgUrl": "2.newContractType.png"
                  }
                ]
              },
              {
                "head": "3. 选择“上传文件起草”，并上传一份原本保存在本地的合同",
                "data": [
                  {
                    "imgUrl": "3.uploadContractDraft.png"
                  }
                ]
              },
              {
                "head": "4. 以一份《设备买卖合同》为例，上传后，会在页面左侧看到合同正文，页面右侧会显示MetaGO为您自动识别、抽取的合同关键信息的概览",
                "data": [
                  {
                    "imgUrl": "4.example.JPG"
                  }
                ]
              },
              {
                "head": "5. 如果您想要修改概览信息，可点选编辑按钮后进行修改",
                "data": [
                  {
                    "imgUrl": "5.previewEdit.png"
                  },
                  {
                    "imgUrl": "5.previewEdit-2.png"
                  }
                ]
              }
            ],
            "id": "a1a0"
          },
          {
            "sectionHead": "二、协同编辑与新版本上传",
            "document": [
              {
                "head": "1. 如果需要在线继续编辑这一份合同，可以点击“编辑”，进入在线协同编辑页面",
                "data": [
                  {
                    "imgUrl": "6.edit.png"
                  }
                ]
              },
              {
                "head": "2. 在编辑页面中，您可以对内容、格式等进行自由调整，还可以点击“协作”，邀请团队中的其他成员同时对这份文件进行编辑",
                "data": [
                  {
                    "imgUrl": "7.cooperation.png"
                  }
                ]
              },
              {
                "head": "3. 当前的编辑告一段落后，您可以将这一段编辑的成果存为一个新版本，方便后续查看、恢复以及与其他版本的比对",
                "data": [
                  {
                    "imgUrl": "8.saveAsNew.png"
                  }
                ]
              },
              {
                "head": "4. 如果需要将当前版本下载下来，可以点击“导出”，存为PDF版本或WORD版本",
                "data": [
                  {
                    "imgUrl": "9.export.png"
                  }
                ]
              },
              {
                "head": "5. 如果合同相对方或其他同事发来了同一份合同的其他版本，您可以直接将新的版本上传",
                "data": [
                  {
                    "imgUrl": "10.uploadNewVersion.png"
                  }
                ]
              }
            ],
            "id": "a1a1"
          },
          {
            "sectionHead": "三、任意版本对比以及评论",
            "document": [
              {
                "head": "1. 无论是针对线上编辑的版本、还是直接上传的线下版本，您可以查看每一个版本的情况",
                "data": [
                  {
                    "imgUrl": "11.versions.png"
                  }
                ]
              },
              {
                "head": "2. 如果需要回复到之前的某一个版本，点击“回退”即可",
                "data": [
                  {
                    "imgUrl": "12.retreat.png"
                  }
                ]
              },
              {
                "head": "3. 我们支持对于任意版本的对比，您可以根据需要进行选择",
                "data": [
                  {
                    "imgUrl": "13.versionSelection.png"
                  }
                ]
              },
              {
                "head": "4. 在每一个版本上，您或您邀请协作的同事，都可以发表评论，用鼠标光标选中需要评论的文本，并点击旁边的按钮",
                "data": [
                  {
                    "imgUrl": "14.commentSelected.png"
                  }
                ]
              },
              {
                "head": "5. 评论后，参与协作的同事能够即时看到，并进行回复",
                "data": [
                  {
                    "imgUrl": "15.comment.png"
                  }
                ]
              },
              {
                "head": "6. 评论后，参与协作的同事能够即时看到，并进行回复",
                "data": [
                  {
                    "imgUrl": "16.scrape.png"
                  }
                ]
              }
            ],
            "id": "a1a2"
          },
          {
            "sectionHead": "四、定稿审批以及签署版上传",
            "document": [
              {
                "head": "1. 在首页的“审批流”中，您可以针对不同类型的合同，设置团队专属的审批流",
                "data": [
                  {
                    "imgUrl": "18.approvalStream.png"
                  }
                ]
              },
              {
                "head": "2. 点击右上角的“添加审批流”",
                "data": [
                  {
                    "imgUrl": "19.addApprovalStream.png"
                  }
                ]
              },
              {
                "head": "3. 对于新建的审批流，您可以自己输入类别和描述",
                "data": [
                  {
                    "imgUrl": "20.approvalTypeDescribe.png"
                  }
                ]
              },
              {
                "head": "4. 在设置审批流程时，您可以自定义分几步审批、有哪些人参与审批。我们支持同一节点多人同时审批，您可以自己设置多人审批之间的逻辑",
                "data": [
                  {
                    "imgUrl": "21.%20multi-personApproval-logic.png"
                  }
                ]
              },
              {
                "head": "5. 当文件已经定稿后，您可以点击“设为定稿”并发起审批",
                "data": [
                  {
                    "imgUrl": "17.setAsFormal.png"
                  }
                ]
              },
              {
                "head": "6. 审批通过后，合同就可以打印签署了！如果您下载当前版本是为了打印签署，我们建议您导出为PDF版本，系统会自动为文件添加二维码身份证，打印成纸质版后仍能一扫得知版本信息，避免签错版本",
                "data": [
                  {
                    "imgUrl": "10.QRCode.png"
                  }
                ]
              },
              {
                "head": "7. 签署完成后，您可以在定稿版本上点击“设为已签署”，并将签署版本回传，我们会帮您自动对比差异，如果签署版与定稿版本不同，会为您提供对比报告",
                "data": [
                  {
                    "imgUrl": "23.setAsSigned.png"
                  }
                ]
              },
              {
                "head": "8. 文件进入“已签署”状态后，我们会自动为您提取这份合同中的关键事件节点，弹出对话框，并经过您的确认添加至日历提醒中",
                "data": [
                  {
                    "imgUrl": "24.calendarSetting.png"
                  }
                ]
              }
            ],
            "id": "a1a3"
          },
          {
            "sectionHead": "五、通过模板起草一份新合同",
            "document": [
              {
                "head": "1. 点击“新建文档”",
                "data": [
                  {
                    "imgUrl": "1.newContract.png"
                  }
                ]
              },
              {
                "head": "2. 选择“起草合同”",
                "data": [
                  {
                    "imgUrl": "2.newContractType.png"
                  }
                ]
              },
              {
                "head": "3. 选择“从模板起草”",
                "data": [
                  {
                    "imgUrl": "3.draftByTemplate.png"
                  }
                ]
              },
              {
                "head": "4. 在模板选择界面中，您使用MetaGO系统内置的模板。",
                "data": [
                  {
                    "imgUrl": "4.templateType-systemTemplate.png"
                  }
                ]
              },
              {
                "head": "5. 使用智能模板，您只要回答左侧的填空、选择题即可快速生成合同",
                "data": [
                  {
                    "imgUrl": "5.i-template.png"
                  }
                ]
              },
              {
                "head": "6. 您还可以在“新建文档”时，选择创建自己的模板",
                "data": [
                  {
                    "imgUrl": "6.templateProduce.png"
                  }
                ]
              },
              {
                "head": "7. 上传本地的word文档进行设置，或者在MetaGO的编辑器上从头开始起草、设置，都可以",
                "data": [
                  {
                    "imgUrl": "7.uploadTemplate.png"
                  }
                ]
              },
              {
                "head": "8. 设置后自己的模板后，您可以在“团队模板”中使用",
                "data": [
                  {
                    "imgUrl": "8.templateType.png"
                  }
                ]
              }
            ],
            "id": "a1a4"
          },
          {
            "sectionHead": "六、管理已签署的合同",
            "document": [
              {
                "head": "1. 除了尚未签署的合同草稿，您还可以通过MetaGO管理您此前已签署过的合同，在“新建文档”中选择“签署合同”",
                "data": [
                  {
                    "imgUrl": "1.uploadSignedContract.png"
                  }
                ]
              },
              {
                "head": "2. 点击上传，无论是WORD，PDF还是JPG格式都可以识别",
                "data": [
                  {
                    "imgUrl": "2.upload.png"
                  }
                ]
              },
              {
                "head": "3. 您也可以点击“批量上传”，然后通过上传压缩包的方式批量上传",
                "data": [
                  {
                    "imgUrl": "3.uploadSignedContract.jpg"
                  }
                ]
              },
              {
                "head": "4. 在“文档列表”中查看您存储的全部文件以及其中的关键信息，右侧还能配置您想要在列表中看到的内容",
                "data": [
                  {
                    "imgUrl": "4.fileList.png"
                  }
                ]
              },
              {
                "head": "5. 在“时间提醒”中查看您导入日历的关键时间点",
                "data": [
                  {
                    "imgUrl": "4.closeToExpire.png"
                  }
                ]
              },
              {
                "head": "6. 在“动态”中查看团队最近的操作",
                "data": [
                  {
                    "imgUrl": "5.activities.png"
                  }
                ]
              }
            ],
            "id": "a1a5"
          }
        ],
        "id": "a1"
      }
    ]


    return {
      category,
      urlPrefix: 'https://uranus-static.oss-cn-beijing.aliyuncs.com/clm/',
      categoryProps: {
        children: 'data',
        label: 'sectionHead',
        id: 'id'
      },
      currentDocument: null,
      expandKeys:[],
    }
  },
  watch:{

  },

  computed: {
    currentComponent() {
      let index = this.$route.query.index;
      if (this.$route.query.index) return index;

      return 'HelpCenterHome'
    }
  },
  components: {
    MetaGoLogo,
    HelpDocument,
    DocumentCategory
  },
  created() {

  },
  mounted() {
    this.initDocument()
  },
  methods: {
    handleJumpToDocument(props,node,event) {

      //关闭文档内容组件
      this.currentDocument = null;
      // colorLog.red('handleJumpToDocument', props,node,event);
      this.$nextTick(()=>{
        //带着新数据重新打开文档内容组件
        this.currentDocument = props;
      })

      //更新url
      this.$router.replace({
        name:'helpCenter',
        query:{
          index: props.id
        }
      })
    },
    handleExpandSection(props) {
    },
    //初始化文档内容
    initDocument(){
      let index = this.$route.query.index
      if(!index){
        index = 'a0a0'
      }
      this.expandKeys = [index];

      this.$nextTick(()=>{
        //直接调dom的click事件来加载内容
        this.$refs.documentCategory.$refs.tree.$el.querySelector(`[data-key=${index}] .el-tree-node__label`).click()
      })
    }
  },
  beforeUnmount() {

  }
}
</script>

<style scoped>
.help-center {
  display: flex;
  height: 100%;
  background: var(--page-gray);
}

.help-center-left {
  min-width: 350px;
  flex: none;
}

.first-shrink-area {
  flex: 1 1 0%;
}

.help-center-content {
  margin: 0 auto;
  display: flex;
}

.hc-left-logo {
  width: 238px;
  margin: 0
}
.help-center-content-document{
  flex-basis: 800px;
  min-width: 400px;

}
.help-center-content-wrap {
  flex-basis: calc((100% - 238px) * .9);
}

.second-shrink-area {
  flex: 1 1 0;
}
.second-shrink-area.throttle{
  flex-grow: .5 ;
}

.help-center-content-document >>> .hc-body{
  width: 100% !important;
}
.help-center-left{
  box-shadow: 0 0 3px var(--gray-2);
}
/*@media screen and (max-width:1100px) {*/
/*  .help-center-content-document >>> .hc-bs-top-h1{*/
/*    --increace:calc((100vw - 375px) / 725);*/
/*    font-size: calc(14px + 2 * var(--increace));*/
/*  }*/
/*}*/
</style>
